<template>
  <b-container>
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('project.link')" />
      <GoldflamTable
        :new-item="newProjectObject"
        :all-items="filteredProjects"
        :table-fields="tableFields"
        :edit-function="editProjectFunction"
        :merge-function="mergeProjectsFunction"
        :delete-function="deleteProject"
        :busy="datatableBusy"
        sort-by="fullName"
      >
        <template v-slot:filters>
          <b-col cols="3">
            <b-select id="customerFilter" v-model="filter.customer" :options="customerOptions">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-customers') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
          <b-col cols="2">
            <b-select id="projectStatusFilter" v-model="filter.status" :options="filterStatusOptions">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-status') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
        </template>
      </GoldflamTable>

      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        :title="$t('project.modal-title')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveProject(editProject)"
        :ok-disabled="modalControl.edit.loading || $v.$invalid"
        :cancel-disabled="modalControl.edit.loading"
        @hidden="resetEditModal"
      >
        <div v-if="modalControl.edit.loading" class="d-flex justify-content-center my-4">
          <b-spinner />
        </div>
        <b-card v-else no-body class="w-100">
          <b-tabs card content-class="m-2">
            <b-tab :title="$t('project.tabs.project')">
              <b-form>
                <b-form-group
                  class="mb-2"
                  label-for="editName"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.name')"
                >
                  <b-form-input
                    id="editName"
                    type="text"
                    v-model="$v.editProject.fullName.$model"
                    :state="validateFormInput('fullName')"
                  />
                </b-form-group>
                <b-form-group
                  class="mt-4 mb-2"
                  label-for="editProjectCode"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.projectCode')"
                >
                  <b-form-input
                    id="editProjectCode"
                    type="text"
                    v-model="$v.editProject.projectCode.$model"
                    :state="validateFormInput('projectCode')"
                  />
                </b-form-group>
                <b-form-group
                  class="mt-4 mb-2"
                  label-for="editProjectStatus"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('general.status')"
                  :description="projectStatusDescription()"
                >
                  <b-form-select
                    id="editProjectStatus"
                    type="text"
                    v-model="editProject.status"
                    :options="statusOptions"
                  />
                </b-form-group>
                <b-form-group
                  class="mt-4 mb-2"
                  label-for="editCustomer"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.customer')"
                >
                  <b-form-select
                    id="editCustomer"
                    v-model="$v.editProject.customer.$model"
                    :options="customerOptions"
                    :state="validateFormInput('customer')"
                  />
                </b-form-group>
                <b-row>
                  <b-alert :show="editTicketGroup !== null" variant="info" class="mt-3 ml-3 mr-3">
                    {{ $t('project.ticketGroup.ticket-group-selected-info') }}
                  </b-alert>
                </b-row>
              </b-form>
            </b-tab>
            <b-tab :title="$t('project.tabs.tickets')">
              <b-form>
                <b-form-group
                  class="mb-2"
                  label-for="editJiraBaseUrl"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.jiraBaseUrl')"
                >
                  <b-form-input
                    id="editJiraBaseUrl"
                    type="text"
                    v-model="$v.editProject.jiraBaseUrl.$model"
                    :state="validateFormInput('jiraBaseUrl')"
                  />
                </b-form-group>
                <b-form-group
                  class="mt-4 mb-2"
                  label-for="ticketIdPrefix"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.ticket-id-prefix')"
                >
                  <b-form-input
                    id="ticketIdPrefix"
                    type="text"
                    v-model="editProject.ticketIdPrefix"
                    :state="!editProject.ticketIdPrefix ? null : isTicketIdPrefixValid"
                  />
                </b-form-group>
                <b-form-group
                  v-if="editProject.id === null"
                  class="mt-4 mb-2"
                  label-for="editTicketGroups"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.ticketGroup.name')"
                >
                  <b-form-select id="editTicketGroups" v-model="editTicketGroup" :options="ticketGroupOptions" />
                </b-form-group>
                <b-form-group v-if="editProject.id === null" class="text-right mb-2">
                  <b-button
                    size="xs"
                    :disabled="editTicketGroup == null || ticketNameOptions === null"
                    class="mr-2 text-uppercase"
                    variant="outline-secondary"
                    @click="selectAllTicketNames"
                    >{{ $t('project.ticketGroup.select-all') }}
                  </b-button>
                  <b-button
                    size="xs"
                    :disabled="editTicketGroup == null || ticketNameOptions === null"
                    class="text-uppercase"
                    variant="outline-secondary"
                    @click="deselectAllTicketNames"
                    >{{ $t('project.ticketGroup.deselect-all') }}
                  </b-button>
                </b-form-group>
                <b-form-group
                  v-if="editProject.id === null"
                  class="mb-0"
                  :description="
                    editTicketGroup == null || ticketNameOptions === null ? '' : $t('project.ticketGroup.description')
                  "
                >
                  <b-card style="background: #f8f9fa">
                    <div class="text-center" v-if="ticketNameOptions === null">
                      {{
                        editTicketGroup === null
                          ? $t('project.ticketGroup.ticket-namess-info-1')
                          : $t('project.ticketGroup.ticket-namess-info-2')
                      }}
                    </div>
                    <b-form-group class="mb-0" v-else-if="editProject.id === null && editTicketGroup !== null">
                      <b-form-checkbox-group
                        class="m-1 mb-0"
                        id="CheckboxGroupTicketNames"
                        v-model="selectedTicketNames"
                        name="flavour-2"
                      >
                        <div class="row">
                          <div class="col-6">
                            <div v-for="(name, index) in ticketNameOptions" :key="name">
                              <b-form-checkbox class="mb-1" v-if="index % 2 === 0" :value="name">
                                {{ name }}
                              </b-form-checkbox>
                            </div>
                          </div>
                          <div class="col-6">
                            <div v-for="(name, index) in ticketNameOptions" :key="name">
                              <b-form-checkbox class="mb-1" v-if="index % 2 === 1" :value="name">
                                {{ name }}
                              </b-form-checkbox>
                            </div>
                          </div>
                        </div>
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-card>
                </b-form-group>
              </b-form>
            </b-tab>
            <b-tab :title="$t('project.tabs.controlling')">
              <b-form>
                <b-form-group
                  class="mb-2"
                  label-for="editBudget"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.budget')"
                >
                  <currency-input
                    id="editBudget"
                    :value="$v.editProject.budget.$model"
                    :locale="$store.getters.getLocale"
                    @change="$v.editProject.budget.$model = $event"
                    :state="validateFormInput('budget')"
                  />
                </b-form-group>
                <b-form-group
                  class="mt-4 mb-2"
                  label-for="editDefaultHourlyRateInCent"
                  label-cols="4"
                  label-cols-lg="2"
                  :label="$t('project.hourlyRate-default')"
                >
                  <currency-input
                    id="editDefaultHourlyRateInCent"
                    :value="$v.editProject.defaultHourlyRateInCents.$model"
                    :locale="$store.getters.getLocale"
                    @change="$v.editProject.defaultHourlyRateInCents.$model = $event"
                    :state="validateFormInput('defaultHourlyRateInCents')"
                  />
                </b-form-group>
                <label class="mt-2 mb-2">{{ $t('project.hourlyRate-perUser') }}</label>
                <b-table
                  class="mt-1"
                  id="hourly-rate-table"
                  small
                  head-variant="light"
                  :fields="hourlyRateTableFields"
                  :items="editProject.projectHourlyRates"
                >
                  <template #table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
                  </template>
                  <template v-slot:cell(name)="row">
                    <b-form-input type="text" size="sm" v-model="row.item.name" :disabled="row.item.disabled" />
                  </template>
                  <template v-slot:cell(hourlyRateInCents)="row">
                    <currency-input
                      :value="row.item.hourlyRateInCents"
                      :locale="$store.getters.getLocale"
                      @change="row.item.hourlyRateInCents = $event"
                      :disabled="row.item.disabled"
                      size="sm"
                    />
                  </template>
                  <template v-slot:cell(users)="row">
                    <multiselect
                      id="multiUserSelect"
                      class="size-sm"
                      v-model="row.item.users"
                      :options="allUsers"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :hide-selected="false"
                      :placeholder="$t('components.multiselect.users.placeholder')"
                      :show-no-results="false"
                      track-by="text"
                      label="text"
                      group-label="label"
                      group-values="options"
                      :group-select="true"
                      :select-label="$t('components.multiselect.select-label')"
                      :select-group-label="$t('components.multiselect.select-group-label')"
                      :selected-label="$t('components.multiselect.selected-label')"
                      :deselect-label="$t('components.multiselect.deselect-label')"
                      :deselect-group-label="$t('components.multiselect.deselect-group-label')"
                      :disabled="row.item.disabled"
                    />
                  </template>
                  <template v-slot:cell(crudActions)="row">
                    <b-button
                      @click="deleteProjectHourlyRate(row.index)"
                      size="xs"
                      variant="outline-danger"
                      class="mt-1"
                      :disabled="row.item.disabled"
                    >
                      <b-icon icon="trash" aria-hidden="true" />
                    </b-button>
                  </template>
                </b-table>
                <b-button @click="addProjectHourlyRate" variant="info" size="sm"
                  >{{ $t('project.hourlyRateTable.addHourlyRate') }}
                </b-button>
              </b-form>
            </b-tab>
            <b-tab :title="$t('project.tabs.costGroup')">
              <b-form>
                <b-table
                  class="mt-1"
                  id="cost-group-table"
                  small
                  head-variant="light"
                  :fields="costGroupTableFields"
                  :items="editProject.projectCostGroups"
                >
                  <template v-slot:cell(name)="row">
                    <b-form-input type="text" size="sm" v-model="row.item.name" />
                  </template>
                  <template v-slot:cell(users)="row">
                    <multiselect
                      id="multiUserSelect-costGroups"
                      class="size-sm"
                      v-model="row.item.users"
                      :options="allUsers"
                      :multiple="true"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :hide-selected="false"
                      :placeholder="$t('components.multiselect.users.placeholder')"
                      :show-no-results="false"
                      track-by="text"
                      label="text"
                      group-label="label"
                      group-values="options"
                      :group-select="true"
                      :select-label="$t('components.multiselect.select-label')"
                      :select-group-label="$t('components.multiselect.select-group-label')"
                      :selected-label="$t('components.multiselect.selected-label')"
                      :deselect-label="$t('components.multiselect.deselect-label')"
                      :deselect-group-label="$t('components.multiselect.deselect-group-label')"
                      :disabled="row.item.disabled"
                    />
                  </template>
                  <template v-slot:cell(crudActions)="row">
                    <b-button
                      @click="deleteProjectCostGroup(row.index)"
                      size="xs"
                      variant="outline-danger"
                      class="mt-1"
                      :disabled="row.item.disabled"
                    >
                      <b-icon icon="trash" aria-hidden="true" />
                    </b-button>
                  </template>
                </b-table>
                <b-button @click="addProjectCostGroup" variant="info" size="sm"
                  >{{ $t('project.costGroupTable.addCostGroup') }}
                </b-button>
              </b-form>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-modal>
      <b-modal
        id="mergeModal"
        v-model="modalControl.merge.show"
        size="xl"
        centered
        :title="$t('project.merge-title')"
        :ok-title="$t('project.merge-title')"
        ok-variant="warning"
        :ok-disabled="isSameProject || !projectsSelected"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="mergeProjects(merge.projectA, merge.projectB)"
      >
        <b-container>
          <b-row class="mb-4">
            <b-col>{{ $t('project.merge-select') }}</b-col>
            <b-col>
              <b-select v-model="merge.projectA" :options="projectOptions" />
            </b-col>
            <b-col>
              <b-select v-model="merge.projectB" :options="projectOptions" />
            </b-col>
          </b-row>
          <b-form>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="editName" :label="$t('project.name')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="editName" type="text" v-model="merge.projectA.fullName" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="editName" type="text" v-model="merge.projectB.fullName" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="editProjectCode" :label="$t('project.projectCode')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="editProjectCode" type="text" v-model="merge.projectA.projectCode" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="editProjectCode" type="text" v-model="merge.projectB.projectCode" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="editBudget" :label="$t('project.budget')" label-align="right" />
              </b-col>
              <b-col>
                <currency-input
                  id="editBudget"
                  :value="merge.projectA.budget"
                  :locale="$store.getters.getLocale"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <currency-input
                  id="editBudget"
                  :value="merge.projectB.budget"
                  :locale="$store.getters.getLocale"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group
                  label-for="editHourlyRateInCent"
                  :label="$t('project.hourlyRate-default')"
                  label-align="right"
                />
              </b-col>
              <b-col>
                <currency-input
                  id="editHourlyRateInCent"
                  :value="merge.projectA.defaultHourlyRateInCents"
                  :locale="$store.getters.getLocale"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <currency-input
                  id="editHourlyRateInCent"
                  :value="merge.projectB.defaultHourlyRateInCents"
                  :locale="$store.getters.getLocale"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col>
                <b-form-group label-for="editJiraBaseUrl" :label="$t('project.jiraBaseUrl')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input id="editJiraBaseUrl" type="text" v-model="merge.projectA.jiraBaseUrl" :disabled="true" />
              </b-col>
              <b-col>
                <b-form-input id="editJiraBaseUrl" type="text" v-model="merge.projectB.jiraBaseUrl" :disabled="true" />
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <b-form-group label-for="editCustomer" :label="$t('project.customer')" label-align="right" />
              </b-col>
              <b-col>
                <b-form-input
                  id="editCustomer"
                  v-model="merge.projectA.customer.fullName"
                  type="text"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <b-form-input
                  id="editCustomer"
                  v-model="merge.projectB.customer.fullName"
                  type="text"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4" />
              <b-col sm="8">
                <b-alert :show="projectsSelected" variant="info">
                  {{ $t('project.merge-modal-message', [this.merge.projectA.fullName, this.merge.projectB.fullName]) }}
                </b-alert>
                <b-alert :show="projectsBelongToDifferentCustomers" variant="warning">
                  {{ $t('project.merge-modal-warning') }}
                </b-alert>
                <b-alert :show="isSameProject" variant="danger">
                  {{ $t('project.merge-identical-msg') }}
                </b-alert>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { validationMixin } from 'vuelidate'
import { decimal, integer, maxLength, minLength, required, url } from 'vuelidate/lib/validators'
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import { i18n } from '@/plugins/i18n'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { StringValidationUtil } from '@/util/StringValidationUtil'
import { UserUtil } from '@/util/UserUtil'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ManageProjects',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { CurrencyInput, GoldflamTable, Multiselect },
  data() {
    return {
      objectModels: {
        project: {
          id: null,
          fullName: '',
          projectCode: '',
          ticketIdPrefix: '',
          budget: 0,
          defaultHourlyRateInCents: 0,
          jiraBaseUrl: null,
          customer: {
            fullName: ''
          },
          projectHourlyRates: [],
          projectCostGroups: []
        },
        projectHourlyRate: {
          id: null,
          name: '',
          hourlyRateInCents: 0,
          users: [],
          disabled: false
        },
        projectCostGroups: {
          id: null,
          name: '',
          users: []
        }
      },
      editProject: {},
      editTicketGroup: null,
      selectedTicketNames: [],
      merge: {
        projectA: {},
        projectB: {}
      },
      modalControl: {
        edit: {
          show: false,
          loading: false
        },
        delete: {
          show: false
        },
        merge: {
          show: false
        }
      },
      allProjects: [],
      allCustomers: [],
      allTicketGroups: [],
      tableFields: [
        { key: 'projectCode', label: this.$t('project.projectCode'), sortable: true },
        { key: 'fullName', label: this.$t('project.name'), sortable: true },
        { key: 'customer.fullName', label: this.$t('project.customer'), sortable: true },
        { key: 'budget', label: this.$t('project.budget'), sortable: true, formatter: 'formatMoney' },
        {
          key: 'defaultHourlyRateInCents',
          label: this.$t('project.hourlyRate-default'),
          sortable: true,
          formatter: 'formatMoney'
        },
        { key: 'status', label: this.$t('general.status'), sortable: true, formatter: 'formatProjectStatus' },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '100px' }
      ],
      hourlyRateTableFields: [
        {
          key: 'name',
          label: this.$t('project.hourlyRateTable.name'),
          sortable: false
        },
        {
          key: 'hourlyRateInCents',
          label: this.$t('project.hourlyRate'),
          sortable: false,
          width: '100px'
        },
        {
          key: 'users',
          label: this.$t('project.hourlyRateTable.users'),
          sortable: false,
          width: '370px'
        },
        {
          key: 'crudActions',
          label: this.$t('general.table.action'),
          class: 'text-center',
          width: '60px'
        }
      ],
      costGroupTableFields: [
        {
          key: 'name',
          label: this.$t('project.costGroupTable.name'),
          sortable: false
        },
        {
          key: 'users',
          label: this.$t('project.costGroupTable.users'),
          sortable: false,
          width: '370px'
        },
        {
          key: 'crudActions',
          label: this.$t('general.table.action'),
          class: 'text-center',
          width: '60px'
        }
      ],
      allUsers: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      datatableBusy: false,
      filter: {
        customer: null,
        status: 'OPEN'
      },
      filterStatusOptions: StaticSelectOpts.projectStatusOptions,
      statusOptions: StaticSelectOpts.projectStatusOptions
    }
  },
  validations: {
    editProject: {
      fullName: {
        required,
        maxLength: maxLength(255)
      },
      projectCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(6)
      },
      budget: {
        decimal
      },
      defaultHourlyRateInCents: {
        integer,
        maxLength: maxLength(11)
      },
      jiraBaseUrl: {
        url,
        maxLength: maxLength(255)
      },
      customer: {
        id: {
          required
        }
      },
      projectHourlyRates: {
        $each: {
          name: {
            required,
            maxLength: maxLength(255)
          },
          hourlyRateInCents: {
            integer,
            maxLength: maxLength(11)
          }
        }
      }
    }
  },
  created() {
    this.editProject = _cloneDeep(this.objectModels.project)
    this.merge.projectA = _cloneDeep(this.objectModels.project)
    this.merge.projectB = _cloneDeep(this.objectModels.project)
  },
  mounted() {
    this.datatableBusy = true
    this.loadProjectList().finally(() => {
      this.datatableBusy = false
    })
    // async loading of customers as these are not required for datatable rendering
    this.loadCustomerList()
    this.loadTicketGroups()
    this.getAllUsers()
  },
  watch: {
    editTicketGroup() {
      this.selectedTicketNames = this.ticketNameOptions
    },
    'modalControl.edit.show'() {
      this.editTicketGroup = null
    }
  },
  computed: {
    isTicketIdPrefixValid() {
      return StringValidationUtil.isValidTicketIdPrefix(this.editProject.ticketIdPrefix)
    },
    projectsSelected() {
      return this.merge.projectA.id !== null && this.merge.projectB.id !== null
    },
    isSameProject() {
      return this.merge.projectA.id === this.merge.projectB.id
    },
    ticketGroupOptions() {
      let options = []
      if (this.editTicketGroup !== null) {
        options.push({
          value: null,
          text: this.$t('project.ticketGroup.ticket-group-select-option-2')
        })
      } else {
        options.push({
          value: null,
          text: this.$t('project.ticketGroup.ticket-group-select-option-1')
        })
      }
      this.allTicketGroups.forEach(ticketGroup => {
        options.push({
          value: ticketGroup,
          text: ticketGroup.name
        })
      })
      return options
    },
    ticketNameOptions() {
      if (this.editTicketGroup !== null && this.editTicketGroup.ticketNames !== null) {
        return this.editTicketGroup.ticketNames.split(/\r?\n/)
      }
      return null
    },
    customerOptions() {
      return this.allCustomers
        .map(customer => {
          return { value: customer, text: customer.fullName }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    projectOptions() {
      return this.allProjects
        .map(project => {
          return {
            value: project,
            text: project.fullName + ' (' + project.customer.fullName + ')'
          }
        })
        .sort((a, b) => a.text.localeCompare(b.text))
    },
    projectsBelongToDifferentCustomers() {
      if (this.merge.projectA.customer.fullName === '' || this.merge.projectB.customer.fullName === '') {
        return false
      } else {
        return (
          this.merge.projectA.customer.fullName !== this.merge.projectB.customer.fullName &&
          this.merge.projectA.customer.id !== this.merge.projectB.customer.id
        )
      }
    },
    filteredProjects() {
      let projects = this.allProjects || []
      let filterByCustomer = this.filter.customer !== null && this.filter.customer !== ''
      let filterByStatus = this.filter.status !== null
      projects = projects.filter(p => {
        if (filterByCustomer && p.customer.id !== this.filter.customer.id) {
          return false
        }
        return !(filterByStatus && p.status !== this.filter.status)
      })
      return projects
    },
    newProjectObject() {
      let newProject = _cloneDeep(this.objectModels.project)
      newProject.status = 'OPEN'
      if (this.filter.customer) {
        newProject.customer = this.filter.customer
      }
      return newProject
    }
  },
  methods: {
    projectStatusDescription() {
      if (this.editProject.hasUnreviewedTtus !== undefined && this.editProject.hasUnreviewedTtus) {
        return this.$t('project.disabled-description')
      } else {
        return ''
      }
    },
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editProject[prop]
      return $dirty ? !$invalid : null
    },
    selectAllTicketNames() {
      this.selectedTicketNames = this.ticketNameOptions
    },
    deselectAllTicketNames() {
      this.selectedTicketNames = []
    },
    saveProject(project) {
      let ticketNames = _cloneDeep(this.selectedTicketNames)

      // map selected users from the multiselect json object structure to the DTO model
      const rates = []
      project.projectHourlyRates.forEach(rate => {
        const users = rate.users.map(e => e.value)
        rate.users = users
        rates.push(rate)
      })
      project.projectHourlyRates = rates

      const groups = []
      project.projectCostGroups.forEach(group => {
        const users = group.users.map(g => g.value)
        group.users = users
        groups.push(group)
      })
      project.projectCostGroups = groups

      let url = '/projects'
      if (project.id) {
        url += `/${project.id}`
      }

      let self = this
      this.postRequest(
        url,
        project,
        new RequestConfig().onSuccess(res => {
          self.displaySuccess(self.$t('project.success-msg'))
          if (!project.id && ticketNames !== null) {
            this.createTicketsForProject(res.data.id, ticketNames)
          }
          project.id = res.data.id
          self.loadProjectList()
        })
      )
    },
    createTicketsForProject(projectId, ticketNames) {
      let url = '/tickets/createForProject'
      if (projectId) {
        url += `/${projectId}`
      }
      let self = this
      this.postRequest(
        url,
        ticketNames,
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(self.$t('ticket.success-msg'))
          })
          .onError(err => {
            self.displayError(err.response.data.message)
          })
      )
    },
    editProjectFunction(project) {
      // show modal immediately in this special case and set loading=true to display the spinner inside the modal until subsequent api calls are finished
      this.modalControl.edit.show = true
      this.modalControl.edit.loading = true

      this.editProject = _cloneDeep(project)

      if (project.id !== null) {
        let self = this
        self.checkProjectHasAnyUnreviewedTtus(project).then(() => {
          self.checkProjectHasAnyReviewedTtus(project).then(() => {
            // set currency null values to 0
            if (self.editProject.budget === null) {
              self.editProject.budget = 0
            }
            if (self.editProject.defaultHourlyRateInCents === null) {
              self.editProject.defaultHourlyRateInCents = 0
            }

            // map selected users to the json object structure needed for the multiselect
            let rates = []
            if (self.editProject.projectHourlyRates !== null) {
              self.editProject.projectHourlyRates.forEach(rate => {
                const users = rate.users.map(entry => {
                  return { text: entry.fullName, value: entry }
                })
                rate.users = users

                // set disabled state for the row (for all components)
                rate.disabled = rate.id !== null && self.editProject.hasReviewedTtus

                // set currency null values to 0
                if (rate.hourlyRateInCents === null) {
                  rate.hourlyRateInCents = 0
                }

                // add mapped rate to intermediate 'edited rates' array
                rates.push(rate)
              })
            }
            self.editProject.projectHourlyRates = rates

            // map selected users to the json object structure needed for the multiselect
            let groups = []
            if (self.editProject.projectCostGroups !== null) {
              self.editProject.projectCostGroups.forEach(group => {
                const users = group.users.map(entry => {
                  return { text: entry.fullName, value: entry }
                })
                group.users = users
                groups.push(group)
              })
            }
            self.editProject.projectCostGroups = groups
            // reset loading state
            self.modalControl.edit.loading = false
          })
        })
      } else {
        this.modalControl.edit.loading = false
      }
    },
    deleteProject(project) {
      let self = this
      const id = project.id
      this.deleteRequest(
        `/projects/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('project.delete-success-msg'))
          self.loadProjectList()
        })
      )
    },
    loadProjectList() {
      let self = this
      return this.getRequest(
        '/projects',
        new RequestConfig().onSuccess(res => {
          self.allProjects = res.data
        })
      )
    },
    loadCustomerList() {
      let self = this
      return this.getRequest(
        '/customers/simple',
        new RequestConfig().onSuccess(res => {
          self.allCustomers = res.data
        })
      )
    },
    loadTicketGroups() {
      let self = this
      return this.getRequest(
        '/ticket_groups',
        new RequestConfig().onSuccess(res => {
          self.allTicketGroups = res.data
        })
      )
    },
    mergeProjects(projectA, projectB) {
      const idA = projectA.id
      const idB = projectB.id
      let self = this
      this.postRequest(
        `/projects/${idA}/merge/${idB}`,
        {},
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('project.merge-success-msg'))
          self.loadProjectList()
        })
      )
    },
    mergeProjectsFunction(projectA, projectB) {
      this.merge.projectA = Object.assign({}, projectA)
      this.merge.projectB = Object.assign({}, projectB)
      this.modalControl.merge.show = true
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.modalControl.edit.loading = false
      this.editProject = _cloneDeep(this.objectModels.project)
      this.$v.$reset()
    },
    checkProjectHasAnyUnreviewedTtus(project) {
      const projectId = project.id
      let self = this
      return this.getRequest(
        `/projects/${projectId}/has_unreviewed_ttus`,
        new RequestConfig().onSuccess(res => {
          self.editProject.hasUnreviewedTtus = res.data
          this.loadProjectStatusOptions()
        })
      )
    },
    checkProjectHasAnyReviewedTtus(project) {
      const projectId = project.id
      let self = this
      return this.getRequest(
        `/projects/${projectId}/has_reviewed_ttus`,
        new RequestConfig().onSuccess(res => {
          self.editProject.hasReviewedTtus = res.data
        })
      )
    },
    loadProjectStatusOptions() {
      if (
        !_isEmpty(this.editProject) &&
        Object.prototype.hasOwnProperty.call(this.editProject, 'hasUnreviewedTtus') &&
        this.editProject.hasUnreviewedTtus
      ) {
        this.statusOptions = [
          { value: 'PLANNED', text: i18n.t('project.status.planned') },
          { value: 'OPEN', text: i18n.t('project.status.open') },
          {
            value: 'CANCELLED',
            text: i18n.t('project.status.cancelled'),
            disabled: this.editProject.hasUnreviewedTtus
          },
          { value: 'FINISHED', text: i18n.t('project.status.finished'), disabled: this.editProject.hasUnreviewedTtus }
        ]
      } else {
        this.statusOptions = StaticSelectOpts.projectStatusOptions
      }
    },
    getAllUsers() {
      let self = this
      this.getRequest(
        '/users',
        new RequestConfig().onSuccess(res => {
          self.allUsers = UserUtil.getSelectOptsForUserListGroupedByStatus(res.data)
        })
      )
    },
    addProjectHourlyRate() {
      if (this.editProject.projectHourlyRates === null) {
        this.editProject.projectHourlyRates = []
      }
      let newHourlyRate = _cloneDeep(this.objectModels.projectHourlyRate)
      this.editProject.projectHourlyRates.push(newHourlyRate)
    },
    deleteProjectHourlyRate(index) {
      this.editProject.projectHourlyRates.splice(index, 1)
    },

    addProjectCostGroup() {
      if (this.editProject.projectCostGroups === null) {
        this.editProject.projectCostGroups = []
      }
      let newProjectCostGroup = _cloneDeep(this.objectModels.projectCostGroups)
      this.editProject.projectCostGroups.push(newProjectCostGroup)
    },
    deleteProjectCostGroup(index) {
      this.editProject.projectCostGroups.splice(index, 1)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
